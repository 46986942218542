import React from "react"
import SEO from "../../components/seo"
// import Button from "../../components/button"
import Footer from "../../components/footer"
import Layout from "../../components/layout"
import Container from "../../components/container"
import presets, { colors } from "../../utils/presets"
import typography, { rhythm, scale, options } from "../../utils/typography"
import { itemListPrice } from "../../utils/sidebar/item-list"
import { Checkbox, Radio } from "antd";
const RadioGroup = Radio.Group;

class PricePage extends React.Component {
    state = {
        valueS: 0,
        valueN: 0,
        valueSAM: 0,
        valueNNM: 0,
        showSAM: false,
        showNNM: false,
    }

    openSAM = (e) => {
        const { valueSAM, valueS } = this.state
        this.setState({
            showSAM: e.target.checked,
            valueS: valueSAM,
        });
        if (!e.target.checked) {
            this.setState({
                valueSAM: 0,
            });
        } else {
            this.setState({
                valueSAM: valueS,
            });
        }
    }

    openNNM = (e) => {
        const {valueNNM, valueN} = this.state
        this.setState({
            showNNM: e.target.checked,
            valueN: valueNNM,
        });
        if (!e.target.checked) {
            this.setState({
                valueNNM: 0,
            });
        } else {
            this.setState({
                valueNNM: valueN,
            });
        }
    }

    onSAMChange = (e) => {
        this.setState({
            valueSAM: e.target.value
        })
    }

    onNNMChange = (e) => {
        this.setState({
            valueNNM: e.target.value
        })
    }

    render() {
        const { showSAM, showNNM, valueSAM, valueNNM } = this.state
        const price = valueSAM + valueNNM
    return (
        <Layout location={this.props.location} itemList={itemListPrice} contentTitle="报价体系">
            <SEO title="报价体系" />
            {/* <div css={{ ...styles.contentHeader, ...styles.sticky }}>
                <h2 css={styles.contentTitle}>
                    <span>
                        报价体系
                    </span>
                </h2>
            </div> */}
            <div css={styles.contact} style={{minHeight: 'calc(100vh - 383px)'}}>
                <Container overrideCSS={{padding: 24}}>
                    <p css={styles.futuraParagraph}>
                    WeADMIN ECC 专注于对局域网、广域网和互联网上的各种异构网络基础架构及其应用的故障监测和性能管理，
                    是集中式、非代理、跨平台监测的系统管理软件，
                    可以对各种操作系统下的服务器、各厂家的网络设备、各类数据库、中间件、邮件系统、WEB系统、电子商务以及各种企业应用进行全面深入的监测管理。
                    </p>
                </Container>
                <Container overrideCSS={{padding: '0 24px'}}>
                  <h3 css={styles.summaryTitle}>参考报价</h3>
                  <Container overrideCSS={{padding: '15px 0'}}>
                  <h4 css={styles.cardHeadLine}>请选择您想购买的模块</h4>
                  <div>
                      <Checkbox value="a" onChange={this.openSAM} checked={this.state.showSAM}><b>服务器</b></Checkbox>
                      <Checkbox value="b" onChange={this.openNNM} checked={this.state.showNNM}><b>网络设备</b></Checkbox>
                  </div>
                  <div css={{paddingTop: 30, display: showSAM ? `` : `none`,}}>
                      <h3 css={styles.cardHeadLine}>监控资源数量（服务器）</h3>
                      <RadioGroup onChange={this.onSAMChange}>
                          <Radio value={6}><b>10台</b></Radio>
                          <Radio value={12}><b>20台</b></Radio>
                          <Radio value={20}><b>50台</b></Radio>
                          <Radio value={36}><b>100台</b></Radio>
                          <Radio value={50}><b>200台</b></Radio>
                      </RadioGroup>
                  </div>
                  <div css={{paddingTop: 30, display: showNNM ? `` : `none`,}}>
                      <h3 css={styles.cardHeadLine}>监控资源数量（网络设备）</h3>
                      <RadioGroup onChange={this.onNNMChange}>
                          <Radio value={6}><b>20台</b></Radio>
                          <Radio value={12}><b>50台</b></Radio>
                          <Radio value={20}><b>100台</b></Radio>
                          <Radio value={36}><b>200台</b></Radio>
                          <Radio value={50}><b>500台</b></Radio>
                          <Radio value={70}><b>1000台</b></Radio>
                      </RadioGroup>
                  </div>
                  <Container overrideCSS={{padding: '15px 0'}}>
                      <p css={styles.futuraParagraph}>以上产品均免费提供一年的软件升级与远程技术支持</p>
                      <p css={styles.futuraParagraph}>价格：人民币（RMB） <span css={{fontSize: `3rem`, color: `green`}}>{price}</span> 万元</p>
                  </Container>
                  </Container>
                </Container>
            </div>
            {/* <div css={styles.footer}>
              <Footer />
            </div> */}
        </Layout>
    )}
}

const styles = {
    contentHeader: {
        alignItems: `center`,
        background: `rgba(255,255,255,0.98)`,
        borderBottom: `1px solid ${colors.ui.light}`,
        display: `flex`,
        flexDirection: `row`,
        height: presets.headerHeight,
        paddingLeft: `${rhythm(3 / 4)}`,
        paddingRight: `${rhythm(3 / 4)}`,
        zIndex: 1,
    },
    cardHeadLine: {
        ...scale(2 / 5),
        lineHeight: 1.2,
        marginTop: 0,
        fontSize: 18
        // [presets.Tablet]: {
        //     fontSize: scale(1 / 10).fontSize,
        // },
        // [presets.Desktop]: {
        //     fontSize: scale(3 / 10).fontSize,
        // },
        // [presets.VHd]: {
        //     fontSize: scale(5 / 10).fontSize,
        // },
        // [presets.VVHd]: {
        //     fontSize: scale(7 / 10).fontSize,
        // },
    },
    contentTitle: {
        ...scale(1),
        color: colors.skyDark,
        lineHeight: 1,
        margin: 0,
    },
    futuraParagraph: {
        fontFamily: options.headerFontFamily.join(`,`),
        fontSize: 14,
        // marginBottom: 0,
    },
    footer: {
        background: `dimGray`,
        fontFamily: typography.options.headerFontFamily.join(`,`),
        paddingTop: 0,
        paddingBottom: 0,
    },
    sticky: {
      position: `sticky`,
      top: `calc(${presets.bannerHeight} - 1px)`,
      [presets.Desktop]: {
        top: `calc(${presets.headerHeight} + ${presets.bannerHeight} - 1px)`,
      },
    },
    summaryTitle: {
      margin: `0 0 10px 0`,
      padding: '0 15px 15px 0px',
      alignItems: `center`,
      borderBottom: `1px solid ${colors.ui.border}`,
      display: `flex`,
      flexDirection: `row`,
      // height: presets.headerHeight,
      // paddingLeft: `${rhythm(3 / 4)}`,
      // paddingRight: `${rhythm(3 / 4)}`,
      // paddingBottom: `0.85rem`,
      zIndex: 1,
    },
}
export default PricePage
